import React, { Component } from 'react'

import ProjetPedagogiquePage from '../containers/ProjetPedagogiquePage'
import PrimaryLayout from '../components/layouts/PrimaryLayout'
import pagesInfos from '../config/i18n/pagesInfos'

const ProjetPedagogique = ({ pageContext }) => {
  return (
    <PrimaryLayout
      language={pageContext.language}
      isDisplayFromHome={false}
      page={pagesInfos.ComponentProjetPedagogique}
    >
      <ProjetPedagogiquePage />
    </PrimaryLayout>
  )
}

export default ProjetPedagogique
