import React, { useState } from 'react'
import Img from 'gatsby-image'
import clsx from 'clsx'
import { useStaticQuery, graphql } from 'gatsby'

import Helmet from 'react-helmet'

import { makeStyles } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid'

import Typography from '@material-ui/core/Typography'

import Section from '../components/Section'
import Heading from '../components/Heading'
import Button from '../components/Button'

import CheckIcon from '@material-ui/icons/DoneAllOutlined'

import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  image: {
    display: 'block',
    height: 'auto',
    width: '100%',
  },
  title: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(15),
  },
  h3: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(5),
  },
  h4: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3),
  },
  groupTitle: {
    marginTop: theme.spacing(5),
  },
  squareContainer: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  squareTitle: {
    color: '#fff',
    margin: '10px 0 0',
    textTransform: 'uppercase',
  },
  squareIcon: {
    color: '#fff',
    fontSize: '3em',
  },
  button: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(4),
  },
}))

const ProjetPedagogiquePage = ({}) => {
  const { t, i18n } = useTranslation()
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)

  const data = useStaticQuery(graphql`
    query {
      backgroundImage: file(relativePath: { eq: "tenniswoman1.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <React.Fragment>
      <Helmet
        title={t('projetPedagogique:head.title')}
        meta={[
          {
            name: 'description',
            content: t('projetPedagogique:head.description'),
          },
        ]}
      />
      <Section bg="#fafcff">
        <Typography
          component="h1"
          variant="h2"
          className={classes.title}
          gutterBottom
        >
          {t('projetPedagogique:title')}
        </Typography>
        <Grid container>
          <Grid item sm={8} xs={12}>
            <Heading
              title={t('projetPedagogique:introduction.title')}
              variant="secondary"
            />
            <p>{t('projetPedagogique:introduction.content')}</p>
            <Typography
              component="h3"
              variant="h5"
              color="secondary"
              className={classes.h3}
            >
              <strong>{t('projetPedagogique:introduction.subtitle')}</strong>
            </Typography>
            <ul>
              {t('projetPedagogique:introduction.list', {
                returnObjects: true,
              }).map(item => (
                <li>{item}</li>
              ))}
            </ul>
          </Grid>
          <Grid item xs={4}>
            <div className={clsx('container-carres', classes.squareContainer)}>
              <div className="container-carres-1">
                <CheckIcon className={classes.squareIcon} />
                <h6
                  className={clsx(
                    'container-carres-titre',
                    classes.squareTitle
                  )}
                >
                  Endurance
                </h6>
              </div>
              <div className="container-carres-2">
                <CheckIcon className={classes.squareIcon} />
                <h6
                  className={clsx(
                    'container-carres-titre',
                    classes.squareTitle
                  )}
                >
                  précision
                </h6>
              </div>
              <div id="DIV_13">
                <CheckIcon className={classes.squareIcon} />
                <h6
                  className={clsx(
                    'container-carres-titre',
                    classes.squareTitle
                  )}
                >
                  force
                </h6>
              </div>
              <div id="DIV_16">
                <CheckIcon className={classes.squareIcon} />
                <h6
                  className={clsx(
                    'container-carres-titre',
                    classes.squareTitle
                  )}
                >
                  souplesse
                </h6>
              </div>
            </div>
          </Grid>
        </Grid>
      </Section>
      <Section bg="#E7F1FE">
        <Grid container>
          <Grid item xs={12} sm={4}>
            <Img
              fluid={data.backgroundImage.childImageSharp.fluid}
              className={classes.image}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <Heading
              title={t('projetPedagogique:methodologie.title')}
              variant="secondary"
            />
            <p>{t('projetPedagogique:methodologie.content')}</p>
            <Typography
              component="h3"
              variant="h5"
              color="secondary"
              className={classes.h3}
            >
              <strong>{t('projetPedagogique:methodologie.subtitle')}</strong>
            </Typography>
            <Grid item container xs={12}>
              <Grid item xs={12} sm={6}>
                <Typography component="h3" variant="subtitle2" color="primary">
                  <strong>{t('projetPedagogique:methodologie.text1')}</strong>
                </Typography>
                <ul>
                  {t('projetPedagogique:methodologie.list1', {
                    returnObjects: true,
                  }).map(item => (
                    <li>{item}</li>
                  ))}
                </ul>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography component="h3" variant="subtitle2" color="primary">
                  <strong>{t('projetPedagogique:methodologie.text2')}</strong>
                </Typography>
                <ul>
                  {t('projetPedagogique:methodologie.list2', {
                    returnObjects: true,
                  }).map(item => (
                    <li>{item}</li>
                  ))}
                </ul>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <Typography
              component="h4"
              variant="subtitle2"
              color="primary"
              className={classes.h4}
            >
              <strong>{t('projetPedagogique:methodologie.text3')}</strong>
            </Typography>
            <ul>
              {t('projetPedagogique:methodologie.list3', {
                returnObjects: true,
              }).map(item => (
                <li>{item}</li>
              ))}
            </ul>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography
              component="h4"
              variant="subtitle2"
              color="primary"
              className={classes.h4}
            >
              <strong>{t('projetPedagogique:methodologie.text4')}</strong>
            </Typography>
            <ul>
              {t('projetPedagogique:methodologie.list4', {
                returnObjects: true,
              }).map(item => (
                <li>{item}</li>
              ))}
            </ul>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography
              component="h4"
              variant="subtitle2"
              color="primary"
              className={classes.h4}
            >
              <strong>{t('projetPedagogique:methodologie.text5')}</strong>
            </Typography>
            <ul>
              {t('projetPedagogique:methodologie.list5', {
                returnObjects: true,
              }).map(item => (
                <li>{item}</li>
              ))}
            </ul>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography
              component="h4"
              variant="subtitle2"
              color="primary"
              className={classes.h4}
            >
              <strong>{t('projetPedagogique:methodologie.text6')}</strong>
            </Typography>
            <ul>
              {t('projetPedagogique:methodologie.list6', {
                returnObjects: true,
              }).map(item => (
                <li>{item}</li>
              ))}
            </ul>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography
              component="h4"
              variant="subtitle2"
              color="primary"
              className={classes.h4}
            >
              <strong>{t('projetPedagogique:methodologie.text7')}</strong>
            </Typography>
            <ul>
              {t('projetPedagogique:methodologie.list7', {
                returnObjects: true,
              }).map(item => (
                <li>{item}</li>
              ))}
            </ul>
          </Grid>
        </Grid>
      </Section>
      <Section bg="#fafcff">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Typography
              component="h4"
              variant="h6"
              color="secondary"
              className={classes.h4}
            >
              <strong>{t('projetPedagogique:bandeau.subtitle1')}</strong>
            </Typography>
            <Typography component="h5" variant="subtitle2">
              <strong>{t('projetPedagogique:bandeau.text1')}</strong>
            </Typography>
            <ul>
              {t('projetPedagogique:bandeau.list1', {
                returnObjects: true,
              }).map(item => (
                <li>{item}</li>
              ))}
            </ul>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              component="h4"
              variant="h6"
              color="secondary"
              className={classes.h4}
            >
              <strong>{t('projetPedagogique:bandeau.subtitle2')}</strong>
            </Typography>
            <Typography component="h5" variant="subtitle2">
              <strong>{t('projetPedagogique:bandeau.text2')}</strong>
            </Typography>
            <ul>
              {t('projetPedagogique:bandeau.list2', {
                returnObjects: true,
              }).map(item => (
                <li>{item}</li>
              ))}
            </ul>
            <Typography component="h5" variant="subtitle2">
              <strong>{t('projetPedagogique:bandeau.text3')}</strong>
            </Typography>
            <ul>
              {t('projetPedagogique:bandeau.list3', {
                returnObjects: true,
              }).map(item => (
                <li>{item}</li>
              ))}
            </ul>
          </Grid>
        </Grid>
      </Section>
      <Section bg="#E7F1FE">
        <Heading
          title={t('projetPedagogique:objets.title')}
          variant="secondary"
        />

        <ul>
          {t('projetPedagogique:objets.list', {
            returnObjects: true,
          }).map(item => (
            <li>{item}</li>
          ))}
        </ul>
        <Grid container justify="center">
          <Button
            className={classes.button}
            href="https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/BTS_Code_Conduite_2019.pdf"
            rel="noopener noreferrer nofollow"
            target="_blank"
          >
            {t('projetPedagogique:objets.button')}
          </Button>
        </Grid>
      </Section>
    </React.Fragment>
  )
}

ProjetPedagogiquePage.defaultProps = {}

export default ProjetPedagogiquePage
